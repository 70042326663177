import { Component } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { ModalServiceAlert } from './service/modal-alert.service';


@Component({
  animations: [
    trigger('fade', [
      transition(':enter', [
        style({ opacity: 0 , scale: 0.5}),
        animate(100, style({ opacity: 1, scale: 1 }))
      ]),
      transition(':leave', animate(100, style({ opacity: 0, scale: 0.5}))),
    ]),
  ],
  standalone: true,
  imports:[CommonModule],
  selector: 'app-modal-alert',
  templateUrl: './modal-alert.component.html',
  styleUrls: ['./modal-alert.component.scss']
})
export class ModalAlertComponent {
  /** Indica si el modal está visible o no */
  showModal: boolean = false;

  /** Título que se mostrará en el modal */
  title: string = '';

  /** Descripción o contenido principal del modal */
  description: string = '';

  /** Tipo de modal que define su estilo visual (info, warning, error, etc) */
  type: string = 'info';

  /** 
   * Configuración de los botones del modal
   * @property {string} acceptText - Texto del botón de aceptar
   * @property {string} cancelText - Texto del botón de cancelar
   * @property {boolean} showCancel - Indica si se muestra el botón de cancelar
   * @property {boolean} canClose - Indica si el modal puede cerrarse
   */
  buttonConfig = { acceptText: 'Aceptar', cancelText: 'Cancelar', showCancel: false, canClose: false }; 

  /** Identificador numérico del registro asociado al modal */
  register!: number;

  /** Valor numérico que indica si el modal puede cerrarse */
  doNotClose: number = 16;

  /**
   * Constructor del componente
   * @param modalService - Servicio que gestiona el comportamiento del modal
   */
  constructor(public modalService: ModalServiceAlert) {}

  /**
   * Método del ciclo de vida que se ejecuta al inicializar el componente.
   * Establece las suscripciones a los observables del servicio de modal.
   */
  ngOnInit() {
    this.modalService.showModal$.subscribe(showModal => {
      this.showModal = showModal;
    });

    this.modalService.modalData$.subscribe(data => {
      this.title = data.title;
      this.description = data.description;
      this.type = data.type;
      this.buttonConfig = data.buttonConfig;
      this.register = data.register;
    });
  }

  /**
   * Maneja el evento de aceptación del modal
   * Invoca el método correspondiente en el servicio
   */
  acceptModal() {
    this.modalService.acceptModal();
  }

  /**
   * Maneja el evento de cierre del modal
   * Invoca el método correspondiente en el servicio
   */
  closeModal() {
    this.modalService.closeModal();
  }

  /**
   * Maneja el evento de rechazo del modal
   * Invoca el método correspondiente en el servicio
   */
  rejectModal() {
    this.modalService.rejectModal();
  }
}
